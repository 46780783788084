.main {
  position: absolute;
  width: 100%;
}

.search-bar-container {
  position: absolute;
  display: flex;
  width: 100%;
  top: 10px;
  margin-top: 38px;
  right: 1px;
  /*right: -20px;*/
  box-shadow: 0em 0.1em 0.05em #ccc;
}

.search-input {
  width: 100%;
  position: relative;
}

.search-input > input {
  border: 1px solid #587fc6!important;
  border-radius: 5px;
  background-color: #acc8fc;
}

.search-button {
  background-color: #587fc6!important;
  border-radius: 5px!important;
}

.search-input > input:focus {
  border: none;
}
/*
.search-bar-container:hover {
  border: 2px solid #110f74;
}
*/
.patients {
  overflow-y: auto;
  width: 100%;
  max-height: 250px;
  position: absolute;
  background-color: #f0e7e7;
  top: 89px;
  right: -1px;
  box-shadow: 0em 0.1em 0.5em #ccc;
}

.patientHead {
  width: 103%;
  position: relative;
  border-bottom: 2px solid #eee3e3;
  background-color: #fcfbfb;
  height: 52px;
  right: -1px;
  box-shadow: 0em 0.01em 0.05em #ccc;
}
.tags {
  margin-left: -100px;
}
.patientHead:hover {
  background-color: #bbadad;
  cursor: pointer;
}


/* Large screens (1208px and up) */
@media (min-width: 1208px) {
  

  .search-bar-container {
    width: 45%;
    margin-top: 0%;
    right: 130px;
  }

  .search-input {
    width: 45%;
  }

  .patients {
    overflow-y: auto;
    width: 45%;
    max-height: 250px;
    position: absolute;
    background-color: #f0e7e7;
    top: 48px;
    right: 130px;
    box-shadow: 0em 0.1em 0.5em #ccc;
  }
  
  .patientHead {
    overflow-y: auto;
    width: 600px;
    right: -1px;
    position: relative;
    border-bottom: 2px solid #eee3e3;
    background-color: #fcfbfb;
    height: 52px;
    box-shadow: 0em 0.01em 0.05em #ccc;
  }
}
