@use "@carbon/styles/scss/spacing";
@use "@carbon/styles/scss/type";
//@import '~@openmrs/esm-styleguide/src/vars';
@import "../scss/vars.scss";

.errorMessage {
  @include type.type-style("heading-compact-02");

  margin-top: 2.25rem;
  margin-bottom: spacing.$spacing-03;
}

.errorCopy {
  margin-bottom: spacing.$spacing-03;
  @include type.type-style("body-01");
  color: $text-02;
}

.desktopHeading {
  h4 {
    @include type.type-style("heading-compact-02");
    color: $text-02;
  }
}

.tabletHeading {
  h4 {
    @include type.type-style("heading-03");
    color: $text-02;
  }
}

.desktopHeading,
.tabletHeading {
  text-align: left;
  text-transform: capitalize;
  margin-bottom: spacing.$spacing-05;

  h4:after {
    content: "";
    display: block;
    width: 2rem;
    padding-top: 0.188rem;
    border-bottom: 0.375rem solid var(--brand-03);
  }
}

.tile {
  text-align: center;
  border: 1px solid $ui-03;
}
